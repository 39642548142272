<template>
  <section>
    <div class="resume-card">
        <div class="resume-header">
            <div class="resume-title">Resultado por período</div>
            <div class="filter-wrapper">
                <Loading :class="{ icon: true, reload: true, loading: loading }" @click="() => !loading && getCashFlowResume()"/>
                <multiselect
                    track-by="id"
                    label="label"
                    placeholder="Todas as contas"
                    v-model="bankAccount"
                    :options="bankAccounts"
                    :allow-empty="false"
                    :showLabels="false"
                    :showNoResults="false"
                    :disabled="false"
                >
                    <template slot="caret">
                        <div class="chevron">
                            <ChevronDown />
                        </div>
                    </template>
                    <template slot="singleLabel" slot-scope="{ option }">
                        {{ option.label }}
                    </template>
                    <template slot="noOptions">
                        Nenhuma opção
                    </template>
                    <template slot="noResult">
                        Nenhum resultado
                    </template>
                </multiselect>
                
                <date-picker
                    id="month-picker"
                    type="month"
                    v-model="currentMonth"
                    format="MMMM [de] YYYY"
                    :clearable="false"
                    :lang="langDatePicker"
                    @blur="resetChevronState"
                ></date-picker>
            </div>
        </div>
        <hr/>
        <div class="values-wrapper">
            <div class="value-content">
                <div class="value-title">Entradas</div>
                <div class="value">
                    <div class="green">
                        {{ parseNumberToMoney(cashFlowResume?.entrances?.value ?? 0) }}
                    </div>
                    <div class="tag" :class="{ green: (cashFlowResume?.entrances?.diff ?? 0) >= 0, red: (cashFlowResume?.entrances?.diff ?? 0) < 0 }">
                        <ArrowUp v-if="(cashFlowResume?.entrances?.diff ?? 0) >= 0" />
                        <ArrowDown v-else />
                        {{parseInt(cashFlowResume?.entrances?.diff ?? 0)}} %
                    </div>
                </div>
            </div>
            <div class="vertical-divider"/>

            <div class="value-content">
                <div class="value-title">Saídas</div>
                <div class="value">
                    <div class="red">
                        {{ parseNumberToMoney(cashFlowResume?.outputs?.value ?? 0) }}
                    </div>
                    <div class="tag" :class="{ green: (cashFlowResume?.outputs?.diff ?? 0) >= 0, red: (cashFlowResume?.outputs?.diff ?? 0) < 0 }">
                        <ArrowUp v-if="(cashFlowResume?.outputs?.diff ?? 0) >= 0" />
                        <ArrowDown v-else />
                        {{parseInt(cashFlowResume?.outputs?.diff ?? 0)}} %
                    </div>
                </div>
            </div>
            <div class="vertical-divider"/>

            <div class="value-content">
                <div class="value-title">Saldo final</div>
                <div class="value">
                    <div>
                        {{ parseNumberToMoney(cashFlowResume?.total?.value ?? 0) }}
                    </div>
                    <div class="tag" :class="{ green: (cashFlowResume?.total?.diff ?? 0) >= 0, red: (cashFlowResume?.total?.diff ?? 0) < 0 }">
                        <ArrowUp v-if="(cashFlowResume?.total?.diff ?? 0) >= 0" />
                        <ArrowDown v-else />
                        {{parseInt(cashFlowResume?.total?.diff ?? 0)}} %
                    </div>
                </div>
            </div>
            <div class="vertical-divider"/>

            <div class="value-content">
                <div class="value-title">Movimentações</div>
                <div class="value">
                    <div>{{ normalizeProductNumber(cashFlowResume?.movements?.value ?? 0, 2) }}</div>
                    <div class="tag" :class="{ green: (cashFlowResume?.movements?.diff ?? 0) >= 0, red: (cashFlowResume?.movements?.diff ?? 0) < 0 }">
                        <ArrowUp v-if="(cashFlowResume?.movements?.diff ?? 0) >= 0" />
                        <ArrowDown v-else />
                        {{parseInt(cashFlowResume?.movements?.diff ?? 0)}} %
                    </div>
                </div>
            </div> 
        </div>
    </div>
  </section>
</template>
<script>
import api from '../api';
import { parseNumberToMoney } from '@/utils/moneyHelper'
import { normalizeProductNumber } from '@/utils/product-helper'
import { getCurrentClinic } from '@/utils/localStorageManager'

export default {
    props: {
        bankAccounts: Array,
    },
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
        ArrowUp: () => import('@/assets/icons/arrow-up.svg'),
        ArrowDown: () => import('@/assets/icons/arrow-down.svg')
    },
    mounted() {
        this.getCashFlowResume()
    },
    data() {
        return {
            loading: false,
            clinic: getCurrentClinic(),
            currentMonth: new Date(),
            bankAccount: null,
            cashFlowResume: null,
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
                },
            },
        }
    },
    methods: {
        parseNumberToMoney,
        normalizeProductNumber,
        resetChevronState() {},
        getCashFlowResume() {
            if (this.loading) return
            this.loading = true
            api.getCashFlowResume(this.clinic.id, this.bankAccount?.id ?? null, this.currentMonth.getMonth() + 1, this.currentMonth.getFullYear())
                .then((response) => {
                    this.cashFlowResume = response.data;
                })
                .catch((error) => this.$toast.error(error.message))
                .finally(() =>(this.loading = false))
        },
    },
    watch: {
        currentMonth() {
            this.getCashFlowResume()
        },
        bankAccount() {
            this.getCashFlowResume()
        }
    }
}
</script>
<style lang="scss" scoped>
.resume-card {
    width: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    .resume-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        .resume-title {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 1.1rem;
        }
        .filter-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .icon {
                width: 48px;
            }

            .datepicker {
                .datepicker-chevrondown {
                    width: 20px;
                    stroke: var(--blue-500);
                }
                width: auto;
                .vx-input-wrapper .vx-input {
                    border: none !important;
                    height: auto !important;
                    color: var(--type-active) !important;
                }
            }
        }
    }
    .values-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow-y: auto;
        .value-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: start;
        }
        .value-title {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 0.8rem;
        }
        .value {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            font-weight: 700;
            font-size: 1.5rem;
            color: var(--dark-blue);
            .green {
                color: #58D19E;
            }
            .red {
                color: var(--states-error);
            }
            .tag {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 4px 8px;
                gap: 4px;
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 12px;
                line-height: 130%;
                border-radius: 16px;
                white-space: nowrap;
                &.green {
                    background-color: #58D19E;
                    color: var(--neutral-000);
                }
                &.red {
                    background-color: var(--states-error);
                    color: var(--neutral-000);
                }
            }
        }
    }
    .vertical-divider {
        width: 1px;
        height: 70px;
        margin: 0 2rem;
        background-color: var(--neutral-200);
    }
    
}
</style>